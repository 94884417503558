@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --safe-area-top: env(safe-area-inset-top);
    --safe-area-right: env(safe-area-inset-right);
    --safe-area-bottom: env(safe-area-inset-bottom);
    --safe-area-left: env(safe-area-inset-left);
}

@layer base {

    html {
        font-size: calc(min(100vw, 375px) / 375 * 10); /* 지원하는 최대사이즈를 450px로 지정 */
        font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        min-height: calc(100% + env(safe-area-inset-top));
    }
    body {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent; /* for removing the highlight */
        outline: none;
    }
    button {
        touch-action: manipulation; /* 버튼의 더블탭 확대 방지 */
    }
    .headline1 {
        @apply text-64 leading-90
    }
    .headline2 {
        @apply text-56 leading-78
    }
    .headline3 {
        @apply text-48 leading-67
    }
    .headline4 {
        @apply text-44 leading-62
    }
    .headline5 {
        @apply text-40 leading-56
    }
    .headline7 {
        @apply text-32 leading-45
    }
    .title1 {
        @apply text-28 leading-39
    }
    .title2 {
        @apply text-24 leading-34
    }
    .title3 {
        @apply text-22 leading-31
    }
    .title4 {
        @apply text-20 leading-28
    }
    .body1 {
        @apply text-18 leading-25
    }
    .body2 {
        @apply text-16 leading-22
    }
    .body3 {
        @apply text-14 leading-20
    }
    .caption {
        @apply text-12 leading-17
    }
    .overline {
        @apply text-10 leading-14
    }

    .scrollbar-hide::-webkit-scrollbar {
        display: none;
    }
    .scrollbar-hide {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }


}

@layer components {

}

@layer utilities {
    .robot-info-gradient {
        background: linear-gradient(273.53deg, #44BFE6 34.6%, #44BFE6 34.6%, rgba(68, 191, 230, 0) 97.38%);
    }
    @keyframes skeleton-gradient {
         0% {
             background-color: rgba(165, 165, 165, 0.05);
         }
         50% {
             background-color: rgba(165, 165, 165, 0.3);
         }
         100% {
             background-color: rgba(165, 165, 165, 0.05);
         }
    }
    .skeleton-animation {
        animation: skeleton-gradient 0.5s infinite ease-in-out;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        @apply appearance-none;
    }
}